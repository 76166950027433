<template>
  <button
    class="
      terminal-button terminal-button-red
      w-5
      h-5
      cursor-pointer
      flex
      content-center
      items-center
    "
  >
    <span class="self-center mx-auto opacity-0 hover:opacity-100 duration-150"> X </span>
  </button>
</template>

<script>
export default {}
</script>

<style></style>
